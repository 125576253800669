import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { CModal, } from "@coreui/react";
import { ToastContainer } from "react-toastify";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { Authcontext } from "../src/views/components/Context/Context";
import {
  getUnitSessionNameFromtable,
  getUnitSessionNameForBookView,
  kampusStartMeeting,
  kampusInfoMeeting,
  kampusStopMeeting,
  kampusJoinMeeting
} from "../src/services/Apiservices";
import Logout from "./views/components/Logout";
import MenuRoutes from "./MenuRoutes";
import "./App.css";
import "./views/components/Menu.css";
import "./views/components/style.css";
import "./views/components/myStyle.css";
import "./views/components/response.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { GrClose } from "react-icons/gr";
import Img_Meet from "../src/Assets/Img_Meet.png";
import sampleprof from "../src/Assets/sample_Img.png";
import GifLoader from "../src/Assets/loading-gif.gif";
import { MdVideocam } from "react-icons/md";
import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CryptoJS from "crypto-js";
// import 'react-toastify/dist/ReactToastify.css';

const initialState = {
  isAuthenticated: false,
  token: "",
  userName: "",
  adminName: "",
  designation: "",
  medium: "",
  mediumId: "",
  institutionName: "",
  refInstID: "",
  academicYear: "",
  yearID: "",
  affiliation: "",
  refAffiliationID: "",
  standard: "",
  section: "",
  classID: "",
  variableID: "",
  userType: "",
  userSubjects: [],
  DayTimeTablePeriod: [],
  DayTimetable: [],
  WeekTimeTablePeriod: [],
  WeekTimetable: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DAY_TIMETABLE":
      return {
        ...state,
        DayTimeTablePeriod: action.payload.DayTimeTablePeriod,
        DayTimetable: action.payload.DayTimetable,
      };
    case "WEEK_TIMETABLE":
      return {
        ...state,
        WeekTimeTablePeriod: action.payload.WeekTimeTablePeriod,
        WeekTimetable: action.payload.WeekTimetable,
      };
    case "KATBOOKS":
    return {
      ...state,
      userSubjects: action.payload
    };
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload?.accessToken,
        userName: action.payload?.userName,
        adminName: action.payload?.adminName,
        designation: action.payload?.designation,
        medium: action.payload?.medium,
        mediumId: action.payload?.mediumId,
        institutionName: action.payload?.institutionName,
        refInstID: action.payload?.refInstID,
        academicYear: action.payload?.academicYear,
        yearID: action.payload?.yearID,
        affiliation: action.payload?.affiliation,
        refAffiliationID: action.payload?.refAffiliationID,
        standard: action.payload?.standard,
        section: action.payload?.section,
        classID: action.payload?.classID,
        variableID: action.payload?.variableID,
        userType: action.payload?.userType,
      };
    case "LOGOUT":
      localStorage.clear();
      return initialState;
    default:
      return state;
  }
};

function App() {
  let KatbookUrl = `https://loadtest.katbook.com`;
  // let KatbookUrl = `http://localhost:8000`;
  const key = "KPOSTKatBooks#$*(@#)!!";

  try {
    document.addEventListener("message", (message) => {
      alert("MESSAGE EVENT", message);
    });
  } catch (err) {
    // console.log(err, "MESSAGEEVENTLISTENER");
  }

  React.useEffect(() => {
    // console.log(localStorage, "LOCAL STORAGE");
    if (localStorage.length > 0 && localStorage.userCreds !== undefined) {
      // console.log(localStorage.userCreds);
      try {
        let retrData = JSON.parse(localStorage.userCreds);

        setuserCredsvalues(retrData);

        // console.log(userCredsvalues, "YYYYYYYYYYYYYYYYYYYYYYYYYY");
        if (retrData) {
          dispatch({ type: "LOGIN", payload: retrData });
        }
      } catch (err) {
        // console.log(err);
      }
    }
  },[]);

  const [state, dispatch] = React.useReducer(reducer, initialState);
  // const { state, dispatch } = useContext(Authcontext);
  const [userCredsvalues, setuserCredsvalues] = useState([]);

  // const navigate = useNavigate();

  const [isloading, setIsloading] = useState(false);
  const [videopopuploading, setvideopopuploading] = useState(false);
  
  const [conferenceData, setconferenceData] = useState("");
  const [secondvalues, setsecondvalues] = useState("");
  const [visible, setVisible] = useState(false);
  const [timerData, settimerData] = useState("");
  const countRef = React.useRef(null);

  // Footer Functions
  const [shoq, setShoq] = useState(true);

  const [KpostIframe, setKpostIframe] = useState(false);

  const closeForm = () => {
    setKpostIframe(false);
    setShoq(true);
  };

  const openForm = () => {
    // alert();
    setShoq(false);
    setKpostIframe(true);
  };

  // Header Functions
  const [showProfile, setShowprofile] = useState(false);
  const [showNotification, setNotification] = useState(false);
  const falseShowProfile = React.useCallback(() => {
    setShowprofile(false);
  });
  const ShowNotification = React.useCallback(() => {
    setNotification(!showNotification);
    falseShowProfile();
  });
  const falseShowNotification = React.useCallback(() => {
    setNotification(false);
  });
  const trueShowNotification = React.useCallback(() => {
    setNotification(true);
  });
  const notofShowProfile = React.useCallback(() => {
    setShowprofile(!showProfile);
    falseShowNotification();
  });
  const disableOnOuterTouch = () => {
    if (showProfile) {
      falseShowProfile();
    }
    if (showNotification) {
      falseShowNotification();
    }
  };

  const tableBgColors = [
    {
      color: "#9B36EA",
    },
    {
      color: "#06C270",
    },
    {
      color: "#FC5F37",
    },
    {
      color: "#3377FF",
    },
    {
      color: "#FF3E84",
    },
    {
      color: "#F78E13",
    },
    {
      color: "#dd3aff",
    },
    {
      color: "#ba4221",
    },
    {
      color: "#9B36EA",
    },
    {
      color: "#06C270",
    },
  ];

  const CuttentDateTime = new Date().toLocaleString("en-US", {
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  });

  const tConvert = (time) => {
    console.log(time,"PendingSessData");
    if(time !== '' && time !== undefined && time !== null){
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }
  };

  const timer = (values) => {
    var seconds = values;
    settimerData("");
    clearInterval();
    // console.log(seconds,"SECONDSSSSSSSSSSSSSSSSSSSSSSSSSSSSS");
    const starttimer = (countdownTimer) => {
      var days = Math.floor(seconds / 24 / 60 / 60);
      var hoursLeft = Math.floor(seconds - days * 86400);
      var hours = Math.floor(hoursLeft / 3600);
      var minutesLeft = Math.floor(hoursLeft - hours * 3600);
      var minutes = Math.floor(minutesLeft / 60);
      var remainingSeconds = seconds % 60;
      const pad = (n) => {
        return n < 10 ? "0" + n : n;
      };

      if (remainingSeconds > 0) {

        settimerData(
          days +
            " Day " +
            pad(hours) +
            " Hr " +
            pad(minutes) +
            " Min " +
            pad(remainingSeconds) +
            " Sec"
        );
        document.getElementById("countdown").innerHTML =
          days +
          " Day " +
          pad(hours) +
          " Hr " +
          pad(minutes) +
          " Min " +
          Math.trunc(pad(remainingSeconds)) +
          " Sec";
      } else {
        settimerData("Completed");
        document.getElementById("countdown").innerHTML = "Meeting Available";
      }

      if (seconds === 0 || seconds === "NaN") {
        clearInterval();
        document.getElementById("countdown").innerHTML = "Completed";
        settimerData("Completed");
      } else {
        seconds--;
      }
      // 1 Day 16Hr 23Min 21 Sec
    };

    countRef.current = setInterval(() => {
      starttimer();
    }, 1000);

  };

  const VideoConfFunc = async (
    SubjectDate,
    SubjectName,
    StartTime,
    EndTime,
    StaffName,
    StaffDesig,
    UnitID,
    sessionID,
    staffID,
    tableID,
    subjectID,
    classId
  ) => {
    setvideopopuploading(true)
    setVisible(!visible);
    document.querySelector("body").style.overflow = "hidden";
    let seconds, GetextactSubjectDate, currentDate;
    seconds = "";
    GetextactSubjectDate = "";
    currentDate = "";
    let getUnitVales = {
      uId: UnitID,
      sId: sessionID,
    };

    let infomeetStatus = {
      classId: classId,
      subjectId: subjectID,
      timeTableId: tableID,
    };

    let responseInfoMeeting = await kampusInfoMeeting(infomeetStatus);

    let getresultUnits = await getUnitSessionNameFromtable(getUnitVales);

    GetextactSubjectDate =
      SubjectDate.split("/")[1] +
      " " +
      SubjectDate.split("/")[0] +
      ", " +
      SubjectDate.split("/")[2];
    // alert(23);
    currentDate = new Date();

    var aq = new Date(`${GetextactSubjectDate} ${StartTime}`),
      // bq = new Date(currentDate);
      bq = currentDate;
    // console.log(((+aq - +bq) / 1000),"TIMER VALUES");
    // var upgradeTime = ((+aq - +bq) / 1000);
    var upgradeTime = (+aq - +bq) / 1000;

    seconds = upgradeTime;

    // var countdownTimer = setInterval(timer(), 1000);

    let conferDataset = {
      subjectName: SubjectName,
      SubjectDate: new Date(SubjectDate).toLocaleDateString("en-us", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      }),
      MeetingDate: SubjectDate.replace("/", "-"),
      StartTime: StartTime,
      EndTime: EndTime,
      StaffName: StaffName,
      StaffDesig: StaffDesig,
      unitName: getresultUnits.unit ? getresultUnits.unit.variableName : "",
      sessionName: getresultUnits.session
        ? getresultUnits.session.variableName
        : "",
      duration: seconds,
      staffID: staffID,
      tableID: tableID,
      subjectID: subjectID,
      classID: classId,
      meetingInfoStatus: responseInfoMeeting.success,
    };

    setsecondvalues(seconds);
    setconferenceData(conferDataset);
    timer(seconds);
    setvideopopuploading(false)
    
  };

  const openBookview = async (unitID, sessionID, subjectID, refSectionID) => {
    let getUnitVales = {
      uId: unitID,
      sId: sessionID,
    };

    let getresultUnits = await getUnitSessionNameForBookView(getUnitVales);

    if (getresultUnits) {

      if (JSON.parse(localStorage.userCreds).userType === "Teaching") {

        // console.log(getresultUnits,"qqqqqqqqqqqqqqqq");
        const BooksText = `${
          getresultUnits.unit.parentVaribaleId
        }/${getresultUnits.unit.typeOfBookId}/{"sessionCode":"${
          getresultUnits.session.sessionCode
        }","_id":"${getresultUnits.session._id}","variableName":"${
          getresultUnits.session.variableName
        }","parentVaribaleId":{"_id":"${
          getresultUnits.unit._id
        }","variableName":"${getresultUnits.unit.variableName}","username":"${
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).userName
        }","code":"${
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).accessToken
        }"}}/${refSectionID}/${subjectID}/${
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).refInstID
        }/${
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).mediumId
        }`;

        const Bookencrypted = CryptoJS.AES.encrypt(
          JSON.stringify(BooksText),
          key
        ).toString();

        var open = window.open(
          `${KatbookUrl}/#/kampus/BookView/${Bookencrypted}`
        );
      } else {
        // console.log(getresultUnits,"qqqqqqqqqqqqqqqq");
        const BooksText = `${
          getresultUnits.unit.parentVaribaleId
        }/${getresultUnits.unit.typeOfBookId}/{"sessionCode":"${
          getresultUnits.session.sessionCode
        }","_id":"${getresultUnits.session._id}","variableName":"${
          getresultUnits.session.variableName
        }","parentVaribaleId":{"_id":"${
          getresultUnits.unit._id
        }","variableName":"${getresultUnits.unit.variableName}","username":"${
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).userName
        }","code":"${
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).accessToken
        }"}}/${
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).classID
        }/${subjectID}/${
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).refInstID
        }/${
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).mediumId
        }`;

        const Bookencrypted = CryptoJS.AES.encrypt(
          JSON.stringify(BooksText),
          key
        ).toString();


        var open = window.open(
          `${KatbookUrl}/#/kampus/BookView/${Bookencrypted}`
        );
      }

      if (open == null || typeof open == "undefined") {
        alert("Turn off your pop-up blocker!");
      }
      // alert(open);
      // alert(typeof(open))
    }

    // setSubData(getresultUnits.session.variableName)
  };

  const JoinStuKampudMeeting = async (
    staffID,
    subjectID,
    tableID,
    classID,
    unitName,
    sessionName,
    subjectName,
    staffName,
    staffDesign,
    MeetingDate,
    StartTime,
    EndTime
  ) => {
    // alert(staffID+"=="+subjectID +"=="+tableID)
    setIsloading(true);
    if (
      localStorage.length > 0 &&
      JSON.parse(localStorage.userCreds).userType === "Teaching"
    ) {
      let meetparams = {
        // classId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).classID,
        isAdmin: true,
        classId: classID,
        subjectId: subjectID,
        refInstId:
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).refInstID,
        meetingStatus: "start",
        KPostId:
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).userName,
        staffId: staffID,
        staffName:
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).adminName,
        timetableId: tableID,
        staffDesignation:
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).designation,
        className:
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).designation +
            " - " +
            subjectName +
            " - " +
            unitName +
            " - " +
            sessionName,
        sessionDate: MeetingDate.replace("/", "-"),
        sessionTime: StartTime + "-" + EndTime,
      };

      let responseMeetings = await kampusStartMeeting(meetparams);

      var open = window.open(responseMeetings.meetURL, "_blank");

      if (open == null || typeof open == "undefined") {
        alert("Turn off your pop-up blocker!");
      }

      clearInterval(countRef.current);
      setIsloading(false);
      setVisible(false);
      document.querySelector("body").style.overflow = "auto";
    }


    if (
      localStorage.length > 0 &&
      JSON.parse(localStorage.userCreds).userType === "Student"
    ) {
      let meetparams = {
        
        classId: classID,
        subjectId: subjectID,
        refInstId:
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).refInstID,
        meetingStatus: "start",
        kpostId:
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).userName,
        staffId: staffID,
        fullName:
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).adminName,
        timeTableId: tableID,
      };

      let responseMeetings = await kampusJoinMeeting(meetparams);

      if(responseMeetings.success === true){

        var open = window.open(responseMeetings.meetingURL, "_blank");

      if (open == null || typeof open == "undefined") {
        alert("Turn off your pop-up blocker!");
      }

      } else {

      }

      clearInterval(countRef.current);
      setIsloading(false);
      setVisible(false);
      document.querySelector("body").style.overflow = "auto";
    }

  };

  const StopKampusMeeting = async (subjectID, classID, tableID) => {
    let meetparams = {
      classId: classID,
      subjectId: subjectID,
      timeTableId: tableID,
    };

    let responseMeetings = await kampusStopMeeting(meetparams);

    var open = window.open(responseMeetings.meetingURL, "_blank");

    if (open == null || typeof open == "undefined") {
      alert("Turn off your pop-up blocker!");
    }

    clearInterval(countRef.current);
    setIsloading(false);
    setVisible(false);
    document.querySelector("body").style.overflow = "auto";
  };

  return (
    <Authcontext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {/* <GlobalContext> */}
      <Router>
        {window.location.pathname !== "/login" && localStorage.length === 0 &&
        <Logout />
        }
        <div>
          {/* { state.isAuthenticated ? ( */}
          <MenuRoutes
            VideoConfFunc={VideoConfFunc}
            tConvert={tConvert}
            CuttentDateTime={CuttentDateTime}
            tableBgColors={tableBgColors}
            openBookview={openBookview}
            showProfile={showProfile}
            falseShowProfile={falseShowProfile}
            notofShowProfile={notofShowProfile}
            ShowNotifications={ShowNotification}
            showNotification={showNotification}
            falseShowNotification={falseShowNotification}
            trueShowNotification={trueShowNotification}
            disableOnOuterTouch={disableOnOuterTouch}
            shoq={shoq}
            KpostIframe={KpostIframe}
            closeForm={closeForm}
            openForm={openForm}
          />
        </div>
      </Router>
      <ToastContainer autoClose={3000} />
      {/* </GlobalContext> */}
      {localStorage.length > 0 && localStorage.userCreds !== undefined && (
        <CModal
          alignment="center"
          visible={visible}
          onClose={() => {
            clearInterval(countRef.current);
            setVisible(false);
            document.querySelector("body").style.overflow = "auto";
          }}
        >
          {/* <CModalBody> */}
          <div className={"Video_Call_Interface"}>

            { videopopuploading ? (
              <>
              <div
              style={{
                minHeight: "330px",
                paddingTop: "25%"
              }}
              >
                <div
                style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
                ><img src={GifLoader} style={{ width: "10%" }} /></div>
                <div
                style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "5%"
              }}
                >
                  <span className={"h5_Eng"}>Please wait ...</span>
                </div>
              </div>

              </>
              ): (
                <div>

            <div>
              <div className={"D_Flex_Chapter"}>
                <div>
                  <span className={"h5_Eng"}>{conferenceData.subjectName}</span>
                </div>
                <div>
                  <GrClose
                    fontWeight={500}
                    size="22"
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={() => {
                      clearInterval(countRef.current);
                      setVisible(false);
                      document.querySelector("body").style.overflow = "auto";
                    }}
                  />
                </div>
              </div>
              <div className={"D_Flex_Div"}>
                <div style={{ width: "60%" }}>
                  <span className={"P_Unit_S"}>{conferenceData.unitName}</span>
                </div>
                <div style={{ width: "50%" }}>
                  <span className={"Session_2"}>
                    <span className={"clarify_dot"}></span>
                    &nbsp; {conferenceData.sessionName}
                  </span>
                </div>
              </div>
            </div>

            <div className={"D_Flex_Time"}>
              <div>
                <span className={"Mon_Apr"}>{conferenceData.SubjectDate}</span>
              </div>
              <div>
                <span className={"Mon_Apr"}>
                  <span className={"clarify_dot"}></span>
                  {conferenceData.StartTime && (
                    <>
                      &nbsp; {tConvert(conferenceData.StartTime)} -{" "}
                      {tConvert(conferenceData.EndTime)}
                    </>
                  )}
                </span>
              </div>
            </div>

            <div className={"D_Flex_M_Profile"}>
              <div>
                <span className={"Meet_Font"}>Meeting Starts in:</span>
                <div className={"Meet_Time_Font"}>
                  {/* 1 Day 16Hr 23Min 21 Sec */}
                  <span id="countdown" class="timer"></span>
                </div>
              </div>
              <div className={"Img_Gap"}>
                <div>
                  <img src={sampleprof} />
                </div>
                <div>
                  <span className={"Meet_Manoj_Font"}>
                    {conferenceData.StaffName}
                  </span>
                  <div className={"Meet_E_T_Font"}>
                    {conferenceData.StaffDesig}
                  </div>
                </div>
              </div>
            </div>

            <div className={"Button_Center"}>
              {timerData === "Completed" ? (
                <>
                  {isloading === true ? (
                    <>
                      <button
                        className={"Button meeting_disable"}
                        style={{ cursor: "no-drop" }}
                      >
                        <MdVideocam size="30" style={{ color: "#FFFFFF" }} />
                        <span className={"Button_J_M"}>
                          {localStorage.length > 0 &&
                          JSON.parse(localStorage.userCreds).userType ===
                            "Teaching"
                            ? "Loading ..."
                            : "Loading ..."}
                        </span>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        style={{
                          cursor:
                            localStorage.length > 0 &&
                            JSON.parse(localStorage.userCreds).userType ===
                              "Student" &&
                            conferenceData.meetingInfoStatus === false
                              ? "auto"
                              : "pointer",
                        }}
                        onClick={() => {
                          if (
                            localStorage.length > 0 &&
                            JSON.parse(localStorage.userCreds).userType ===
                              "Student" &&
                            conferenceData.meetingInfoStatus === true
                          ) {
                            JoinStuKampudMeeting(
                              conferenceData.staffID,
                              conferenceData.subjectID,
                              conferenceData.tableID,
                              conferenceData.classID,
                              conferenceData.unitName,
                              conferenceData.sessionName,
                              conferenceData.subjectName,
                              conferenceData.StaffName,
                              conferenceData.StaffDesign,
                              conferenceData.MeetingDate,
                              conferenceData.StartTime,
                              conferenceData.EndTime
                            );
                          } else if (
                            localStorage.length > 0 &&
                            JSON.parse(localStorage.userCreds).userType ===
                              "Student" &&
                            conferenceData.meetingInfoStatus === false
                          ) {
                          } else {
                            JoinStuKampudMeeting(
                              conferenceData.staffID,
                              conferenceData.subjectID,
                              conferenceData.tableID,
                              conferenceData.classID,
                              conferenceData.unitName,
                              conferenceData.sessionName,
                              conferenceData.subjectName,
                              conferenceData.StaffName,
                              conferenceData.StaffDesign,
                              conferenceData.MeetingDate,
                              conferenceData.StartTime,
                              conferenceData.EndTime
                            );
                          }
                        }}
                        className={"Button meeting_avail"}
                      >
                        <MdVideocam size="30" style={{ color: "#FFFFFF" }} />
                        <span className={"Button_J_M"}>
                          {localStorage.length > 0 &&
                          JSON.parse(localStorage.userCreds).userType ===
                            "Teaching"
                            ? conferenceData.meetingInfoStatus === true
                              ? "Rejoin Meeting"
                              : "Start Meeting"
                            : conferenceData.meetingInfoStatus === true
                            ? "Join Meeting"
                            : "Please wait until your teacher join"}
                        </span>
                      </button>

                      {localStorage.length > 0 &&
                        JSON.parse(localStorage.userCreds).userType ===
                          "Teaching" &&
                        conferenceData.meetingInfoStatus === true && (
                          <button
                            onClick={() => {
                              StopKampusMeeting(
                                conferenceData.subjectID,
                                conferenceData.classID,
                                conferenceData.tableID
                              );
                            }}
                            className={"Button meeting_avail"}
                            style={{ background: "red", marginLeft: "2%" }}
                          >
                            <MdVideocam
                              size="30"
                              style={{ color: "#FFFFFF" }}
                            />
                            <span className={"Button_J_M"}>
                              {localStorage.length > 0 &&
                              JSON.parse(localStorage.userCreds).userType ===
                                "Teaching"
                                ? "End Meeting"
                                : ""}
                            </span>
                          </button>
                        )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <button
                    className={"Button meeting_disable"}
                    style={{ cursor: "no-drop" }}
                  >
                    <MdVideocam size="30" style={{ color: "#FFFFFF" }} />
                    <span className={"Button_J_M"}>
                      {localStorage.length > 0 &&
                      JSON.parse(localStorage.userCreds).userType === "Teaching"
                        ? "Start Meeting"
                        : "Join Meeting"}
                    </span>
                  </button>
                </>
              )}
            </div>

            </div>
              )}

          </div>
          {/* </CModalBody> */}
        </CModal>
      )}
      
    </Authcontext.Provider>
  );
}

export default App;
